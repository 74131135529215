import { Grid, Link, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { format } from "../gestisciProgetti/TableProgetti";
import { labels } from "../../../labels";
import { fetchAttivitaInnovazione } from "../../../modules/datiOsservatorio";
import { PATH_DETTAGLI_PROGETTO, PATH_SCHEDA_PROFILO } from "../../../path";
import { savePath, setIdProgetto } from "../../../modules/configuration";
import { useNavigate } from "react-router-dom";

const {
  ABSTRACT,
  SUPPORTO_PROGETTO,
  NOTE,
  DATA_INIZIO_PROGETTO,
  TITOLO_PROGETTO,
  GRADO_SCUOLA,
  TEMPO_REALIZZAZIONE,
  ANNO_REALIZZAZIONE,
  AREA_COINVOLTA,
  TIPO_BENEFICIARI,
  CO_RESPONSABILE,
  FONDI_CONTESTO,
  REFERENTE_PROGETTO,
} = labels;

export const AnagraficaDettInn = (props) => {
  let progetto = props.progetto;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //ottengo l'elenco delle attività per visualizzazione attività in base al valore scelto come id ambiente nel progetto

  useEffect(() => {
    dispatch(fetchAttivitaInnovazione(progetto.id_ambiente));
    // eslint-disable-next-line
  }, [progetto]);

  return (
    <Grid container>
      <Grid item xs={12} md={6} sm={3} xl={3}>
        <Typography className="textTitle">{TITOLO_PROGETTO}</Typography>
        <Typography className="dato">
          {progetto.titolo_progetto === null ? <Typography className="dato">-</Typography> : progetto.titolo_progetto}
        </Typography>
        <Typography className="textTitle">{REFERENTE_PROGETTO}</Typography>
        {/* <Link
          onClick={() => {
            dispatch(setIdProgetto(progetto.uuid_progetto));
            navigate(PATH_SCHEDA_PROFILO);
            dispatch(savePath(PATH_DETTAGLI_PROGETTO));
          }}
        > */}
          <Typography className="dato" sx={{ cursor: "pointer !important" }}>
            {" "}
            {(progetto.referente_cogn === null ? "-" : progetto.referente_cogn) +
              " " +
              (progetto.referente_nome === null ? "-" : progetto.referente_nome)}{" "}
          </Typography>
        {/* </Link> */}
        <Typography className="textTitle">{ABSTRACT}</Typography>
        <Typography className="dato">
          {progetto.descr_progetto === null ? <Typography className="dato">-</Typography> : progetto.descr_progetto}{" "}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6} sm={3} xl={3}>
        <Typography className="textTitle">{DATA_INIZIO_PROGETTO}</Typography>
        <Typography className="dato"> {progetto.data_inizio !== undefined ? format(new Date(progetto.data_inizio), "dd-MM-yyyy") : null}</Typography>
        <Typography className="textTitle">{TEMPO_REALIZZAZIONE}</Typography>
        <Typography className="dato">
          {progetto.descr_tempo_realiz === null ? <Typography className="dato">-</Typography> : progetto.descr_tempo_realiz}
        </Typography>
        <Typography className="textTitle">{ANNO_REALIZZAZIONE}</Typography>
        <Typography className="dato">
          {" "}
          {progetto.descr_anno_realiz === null ? <Typography className="dato">-</Typography> : progetto.descr_anno_realiz}
        </Typography>
        <Typography className="textTitle">{AREA_COINVOLTA}</Typography>
        <Typography className="dato">
          {" "}
          {progetto.descr_ambiente === null ? <Typography className="dato">-</Typography> : progetto.descr_ambiente}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6} sm={3} xl={3}>
        <Typography className="textTitle">{GRADO_SCUOLA}</Typography>
        <Typography className="dato">
          {" "}
          {progetto.descr_grado_scuola === null ? <Typography className="dato">-</Typography> : progetto.descr_grado_scuola}{" "}
        </Typography>
        <Typography className="textTitle">{FONDI_CONTESTO}</Typography>
        <Typography className="dato">{progetto.descr_fondi === null ? <Typography className="dato">-</Typography> : progetto.descr_fondi}</Typography>
        <Typography className="textTitle">{TIPO_BENEFICIARI}</Typography>
        {progetto.beneficiari !== undefined ? (
          progetto.beneficiari.length !== 0 ? (
            progetto.beneficiari.map((elem, ind) => (
              <Typography className="dato" key={ind}>
                {elem.descrizione}
              </Typography>
            ))
          ) : (
            <Typography className="dato">-</Typography>
          )
        ) : (
          <Typography className="dato">-</Typography>
        )}
        <Typography className="textTitle">{CO_RESPONSABILE}</Typography>
        <Typography className="dato">
          {(progetto.coReferente_cognome === null ? <Typography className="dato">-</Typography> : progetto.coReferente_cognome) +
            " " +
            (progetto.coReferente_nome === null ? <Typography className="dato">-</Typography> : progetto.coReferente_nome)}{" "}
        </Typography>{" "}
      </Grid>
      <Grid item xs={12} md={6} sm={3} xl={3}>
        <Typography className="textTitle">{SUPPORTO_PROGETTO}</Typography>
        <Typography className="dato">
          {progetto.nome_mentor === null ? <Typography className="dato">-</Typography> : progetto.nome_mentor}{" "}
        </Typography>
        <Typography className="textTitle">{NOTE}</Typography>
        <Typography className="dato">{progetto.annote === null ? <Typography className="dato">-</Typography> : progetto.annote} </Typography>
      </Grid>
    </Grid>
  );
};
