import { styled } from "@mui/material/styles";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Link, Grid } from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { KeyboardArrowDown as KeyboardArrowDownIcon } from "@mui/icons-material";
import { SchedaIstitutoIconLink } from "./SchedaIstitutoIconLink";
import { useDispatch, useSelector } from "react-redux";
import { getVal1Slice, getVal2Slice, savePath, setCodiceMecc, setFilterSort } from "../../../../modules/configuration";
import { labels } from "../../../../labels";
import { useNavigate } from "react-router-dom";
import { fetchSezioneIstituto } from "../../../../modules/istituti";
import { fetchSezioneProgettiIstituto } from "../../../../modules/datiProgetti";
import { PATH_ISTITUTI_SCOLASTICI, PATH_SCHEDA_ISTITUTO } from "../../../../path";

const { DENOMINAZIONE, CODICE_MECCANOGRAFICO, TIPOLOGIA_ISTITUTO, COMUNE, BUTTON_SCHEDA_ISTITUTO } = labels;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    padding: "10px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#DEA4BD",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const createData = (denominazione, codiceMeccanografico, tipologia, comune, schedaIstituto) => ({
  denominazione,
  codiceMeccanografico,
  tipologia,
  comune,
  schedaIstituto,
});

export const TableRicerca = (props) => {
  const valore1slice = useSelector(getVal1Slice);
  const valore2slice = useSelector(getVal2Slice);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let istituti = props.data;
  let rows = istituti.map((elem, index) =>
    createData(
      <Link
        onClick={() => {
          dispatch(setCodiceMecc(elem.codice_scuola));
          dispatch(fetchSezioneIstituto(elem.codice_scuola));
          dispatch(fetchSezioneProgettiIstituto(elem.codice_scuola));
          navigate(PATH_SCHEDA_ISTITUTO);
          dispatch(savePath(PATH_ISTITUTI_SCOLASTICI));
        }}
        sx={{ color: "black !important", textDecorationColor: "black !important", cursor: "pointer" }}
      >
        {elem.nome}
      </Link>,
      elem.codice_scuola,
      elem.descr_istituto,
      elem.descr_com,
      <SchedaIstitutoIconLink codiceScuola={elem.codice_scuola} />
    )
  );

  return (
    <TableContainer
      component={Paper}
      sx={{
        "@media (max-width:900px)": { display: "none" },
      }}
    >
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center" sx={{ width: "400px" }}>
              <Grid container justifyContent={"center"}>
                <Grid item>
                  <Typography className="fontTitleColumnTable">{DENOMINAZIONE}</Typography>
                </Grid>
                <Grid item>
                  <KeyboardArrowDownIcon onClick={() => dispatch(setFilterSort(DENOMINAZIONE))} sx={{ cursor: "pointer" }} />
                </Grid>
              </Grid>
            </StyledTableCell>
            <StyledTableCell align="center" sx={{ width: "200px" }}>
              <Grid container justifyContent={"center"}>
                <Grid item>
                  <Typography className="fontTitleColumnTable">{CODICE_MECCANOGRAFICO}</Typography>
                </Grid>
                <Grid item>
                  <KeyboardArrowDownIcon onClick={() => dispatch(setFilterSort(CODICE_MECCANOGRAFICO))} sx={{ cursor: "pointer" }} />
                </Grid>
              </Grid>
            </StyledTableCell>
            <StyledTableCell align="center" sx={{ width: "200px" }}>
              <Grid container justifyContent={"center"}>
                <Grid item>
                  <Typography className="fontTitleColumnTable">{TIPOLOGIA_ISTITUTO}</Typography>
                </Grid>
                <Grid item>
                  <KeyboardArrowDownIcon onClick={() => dispatch(setFilterSort(TIPOLOGIA_ISTITUTO))} sx={{ cursor: "pointer" }} />
                </Grid>
              </Grid>
            </StyledTableCell>
            <StyledTableCell align="center" sx={{ width: "100px" }}>
              <Grid container justifyContent={"center"}>
                <Grid item>
                  <Typography className="fontTitleColumnTable">{COMUNE}</Typography>
                </Grid>
                <Grid item>
                  <KeyboardArrowDownIcon onClick={() => dispatch(setFilterSort(COMUNE))} sx={{ cursor: "pointer" }} />
                </Grid>
              </Grid>
            </StyledTableCell>

            <StyledTableCell align="center" sx={{ width: "150px" }}>
              {" "}
              <Typography
                sx={{
                  fontWeight: "Bold",
                  fontSize: "18px",
                }}
              >
                {BUTTON_SCHEDA_ISTITUTO}
              </Typography>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.slice(valore1slice, valore2slice).map((row, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell align="center">{row.denominazione}</StyledTableCell>
              <StyledTableCell align="center">{row.codiceMeccanografico}</StyledTableCell>
              <StyledTableCell align="center">{row.tipologia}</StyledTableCell>
              <StyledTableCell align="center">{row.comune}</StyledTableCell>
              <StyledTableCell align="center">{row.schedaIstituto}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
