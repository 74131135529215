import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { Table, Grid, TableBody, Link, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import PrintIcon from "@mui/icons-material/Print";
import { KeyboardArrowDown as KeyboardArrowDownIcon, MobileScreenShareOutlined } from "@mui/icons-material";
import { Titolo, DataInserimento, Referente, Provincia, OrdineScuola } from "../../shared";
import {
  setDropDown,
  setDropDown2,
  setDropDown3,
  setDropDown4,
  setDropDown5,
  setDropDown6,
  getDropDown,
  getDropDown2,
  getDropDown3,
  getDropDown4,
  getDropDown5,
  getDropDown6,
  getVal1Slice,
  getVal2Slice,
  getFilterSort,
  getFilterProvincia,
  getValueCheckOrdineSc,
  getFilterImieiP,
  getFilterDataInizio,
  getFilterDataInizioOrdine,
  getFilterTitle,
  getDropDownCompletameto,
  setDropDownCompletamento,
  getFilterCompletamento,
  setCodiceMecc,
  savePath,
  setIdProgetto,
} from "../../../modules/configuration";
import { Denominazione } from "../../shared/componentiDropDown/Denominazione";
import { format } from "../gestisciProgetti/TableProgetti";
import { Completamento } from "../../shared/componentiDropDown/Completamento";
import { Progress } from "../../shared/Progress";
import { labels } from "../../../labels";
import { DettagliLink } from "../dettagliProgetto/DettagliLink";
import { fetchSezioneIstituto } from "../../../modules/istituti";
import { fetchSezioneProgettiIstituto } from "../../../modules/datiProgetti";
import { PATH_SCHEDA_ISTITUTO, PATH_SCHEDA_PROFILO } from "../../../path";
import { useNavigate } from "react-router-dom";

const {
  COMPLETAMENTO,
  BUTTON_SCARICA,
  BUTTON_CONDIVIDI,
  AZIONI,
  DENOMINAZIONE_ISTITUTO,
  PROVINCIA,
  ORDINE_SCUOLA,
  TITOLO_PROGETTO,
  REFERENTE,
  DATA_INSERIMENTO,
  DETTAGLI,
} = labels;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    padding: "10px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#DEA4BD",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const createData = (completamento, denominazioneScuola, provincia, ordineScuola, titolo, referente, dataInserimento, dettagliProgetto, azioni) => ({
  completamento,
  denominazioneScuola,
  provincia,
  ordineScuola,
  titolo,
  referente,
  dataInserimento,
  dettagliProgetto,
  azioni,
});

export const MainTableProgetti = (props) => {
  const dispatch = useDispatch();
  const dropdown = useSelector(getDropDown);
  const dropdown2 = useSelector(getDropDown2);
  const dropdown3 = useSelector(getDropDown3);
  const dropdown4 = useSelector(getDropDown4);
  const dropdown5 = useSelector(getDropDown5);
  const dropdown6 = useSelector(getDropDown6);
  const data = props.progetti;
  const valore1slice = useSelector(getVal1Slice);
  const valore2slice = useSelector(getVal2Slice);
  const filterSort = useSelector(getFilterSort);
  const filterProvincia = useSelector(getFilterProvincia);
  const filterOrdineSc = useSelector(getValueCheckOrdineSc);
  const filterImieiP = useSelector(getFilterImieiP);
  const filterDataInizio = useSelector(getFilterDataInizio);
  const filterDataInizioOrdine = useSelector(getFilterDataInizioOrdine);
  const filterTitle = useSelector(getFilterTitle);
  const dropdownCompletamento = useSelector(getDropDownCompletameto);
  const filterCompletamento = useSelector(getFilterCompletamento);
  const navigate = useNavigate();

  let dataFilter = data;
  //ordino di default
  if (filterDataInizio !== true) {
    function SortArray(x, y) {
      let varx = x.data_inserimento;
      let vary = y.data_inserimento;
      if (x.data_inserimento === null) {
        varx = "1900-01-01 00:00:00";
      }
      return new Date(varx) - new Date(vary);
    }
    dataFilter = [...dataFilter].sort(SortArray);
    dataFilter = dataFilter.reverse();
  }
  //filtro riordinamento alfabetico titolo
  if (filterTitle === true) {
    function SortArray(x, y) {
      return x.titolo_progetto.localeCompare(y.titolo_progetto, "fr", {
        ignorePunctuation: true,
      });
    }
    dataFilter = [...dataFilter].sort(SortArray);
  }

  //filtro riordinamento alfabetico Denominazione scuola
  if (filterSort === true) {
    function SortArray(x, y) {
      return x.nomeScuola.localeCompare(y.nomeScuola, "fr", {
        ignorePunctuation: true,
      });
    }
    dataFilter = [...dataFilter].sort(SortArray);
  }

  //filtro ricerca provincia
  if (filterProvincia === true) {
    function SortArray(x, y) {
      return x.provincia.localeCompare(y.provincia, "fr", {
        ignorePunctuation: true,
      });
    }
    dataFilter = [...dataFilter].sort(SortArray);
  }

  // filtro ordine scuola
  if (filterOrdineSc === true) {
    function SortArray(x, y) {
      let varx = x.descr_grado_scuola;
      let vary = y.descr_grado_scuola;
      if (x.descr_grado_scuola === null) {
        varx = "z";
      }
      return varx.localeCompare(vary, "fr", {
        ignorePunctuation: true,
      });
    }
    dataFilter = [...dataFilter].sort(SortArray);
  }

  //filtrio Referente
  if (filterImieiP === true) {
    function SortArray(x, y) {
      let varx = x.referente_cogn;
      let vary = y.referente_cogn;
      if (x.referente_cogn === null) {
        varx = "z";
      }
      return varx.localeCompare(vary, "fr", {
        ignorePunctuation: true,
      });
    }
    dataFilter = [...dataFilter].sort(SortArray);
  }

  //filtro riordinamento completamento
  if (filterCompletamento === "Ordina dal più completo") {
    function SortArray(x, y) {
      let varx = x.completamento;
      let vary = y.completamento;
      return vary - varx;
    }
    dataFilter = [...dataFilter].sort(SortArray);
  }
  if (filterCompletamento === "Ordina dal meno completo") {
    function SortArray(x, y) {
      let varx = x.completamento;
      let vary = y.completamento;
      return varx - vary;
    }
    dataFilter = [...dataFilter].sort(SortArray);
  }

  //filtro riordinamento data inserimento dec e crescente
  if (filterDataInizio === true) {
    function SortArray(x, y) {
      let varx = x.data_inserimento;
      let vary = y.data_inserimento;
      if (x.data_inserimento === null) {
        varx = "1900-01-01 00:00:00";
      }
      return new Date(varx) - new Date(vary);
    }
    dataFilter = [...dataFilter].sort(SortArray);

    if (filterDataInizioOrdine !== "dataMenoRec") {
      dataFilter = dataFilter.reverse();
    }
  }

  const rows = dataFilter.map((elemento, index) =>
    createData(
      <Progress progress={elemento.completamento} />,
      <Link
        onClick={() => {
          dispatch(setCodiceMecc(elemento.codice_scuola));
          dispatch(fetchSezioneIstituto(elemento.codice_scuola));
          dispatch(fetchSezioneProgettiIstituto(elemento.codice_scuola));
          navigate(PATH_SCHEDA_ISTITUTO);
          dispatch(savePath(props.path));
        }}
        sx={{ color: "black !important", textDecorationColor: "black !important", cursor: "pointer" }}
      >
        <Typography
          sx={{
            marginTop: "10px",
            marginLeft: "10px",
          }}
        >
          {" "}
          {elemento.nomeScuola === null ? " " : elemento.nomeScuola}
        </Typography>
      </Link>,
      elemento.provincia,
      elemento.descr_grado_scuola,
      elemento.titolo_progetto,
      // <Link
      //   sx={{ color: "black !important", textDecorationColor: "black !important", cursor: "pointer !important" }}
      //   onClick={() => {
      //     dispatch(setIdProgetto(elemento.uuid_progetto));
      //     navigate(PATH_SCHEDA_PROFILO);
      //     dispatch(savePath(props.path));
      //   }}
      // >
        (elemento.referente_cogn !== null ? elemento.referente_cogn : "") + " " + (elemento.referente_nome !== null ? elemento.referente_nome : ""),
      // </Link>,
      format(new Date(elemento.data_inserimento), "dd-MM-yyyy"),
      <DettagliLink path={props.path} id={elemento.uuid_progetto} />,
      <Grid container>
        <Grid item xs={6}>
          <Link
            onClick={() => window.open(window.origin + "/shared/v1/projects/" + elemento.uuid_progetto + "?print=true", "_blank")}
            sx={{
              color: "black !important",
              cursor: "pointer",
              textDecoration: "none",
              ":hover": {
                textDecoration: "underline",
              },
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <PrintIcon sx={{ color: "black" }} />
              </Grid>

              <Grid item xs={12}>
                {BUTTON_SCARICA}
              </Grid>
            </Grid>
          </Link>
        </Grid>
        <Grid item xs={6}>
          <Link
            onClick={() => window.open(window.origin + "/shared/v1/projects/" + elemento.uuid_progetto + "?print=false", "_blank")}
            sx={{
              color: "black !important",
              cursor: "pointer",
              textDecoration: "none",
              ":hover": {
                textDecoration: "underline",
              },
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <MobileScreenShareOutlined sx={{ color: "black" }} />
              </Grid>
              <Grid item xs={12}>
                {BUTTON_CONDIVIDI}
              </Grid>
            </Grid>
          </Link>
        </Grid>
      </Grid>
    )
  );

  return (
    <TableContainer
      component={Paper}
      sx={{
        "@media (max-width:1024px)": { display: "none" },
      }}
      className="gestisciProgetti"
    >
      <Table sx={{ minWidth: 700, cursor: "default" }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center" sx={{ width: "168px" }}>
              <Grid container justifyContent={"center"}>
                <Grid item>
                  <Typography className="fontTitleColumnTable">{COMPLETAMENTO}</Typography>
                  {dropdownCompletamento ? <Completamento /> : null}
                </Grid>
                <Grid item>
                  <KeyboardArrowDownIcon onClick={() => dispatch(setDropDownCompletamento())} sx={{ cursor: "pointer" }} />
                </Grid>
              </Grid>
            </StyledTableCell>
            <StyledTableCell align="center" sx={{ width: "280px" }}>
              <Grid container justifyContent={"center"}>
                <Grid item>
                  <Typography className="fontTitleColumnTable">{DENOMINAZIONE_ISTITUTO}</Typography>
                  {dropdown ? <Denominazione /> : null}
                </Grid>
                <Grid item>
                  <KeyboardArrowDownIcon onClick={() => dispatch(setDropDown())} sx={{ cursor: "pointer" }} />
                </Grid>
              </Grid>
            </StyledTableCell>
            <StyledTableCell align="center" sx={{ width: "120px" }}>
              <Grid container justifyContent={"center"}>
                <Grid item>
                  <Typography className="fontTitleColumnTable">{PROVINCIA}</Typography>
                  {dropdown2 ? <Provincia /> : null}
                </Grid>
                <Grid item>
                  <KeyboardArrowDownIcon onClick={() => dispatch(setDropDown2())} sx={{ cursor: "pointer" }} />
                </Grid>
              </Grid>
            </StyledTableCell>
            <StyledTableCell align="center" sx={{ width: "185px" }}>
              <Grid container justifyContent={"center"}>
                <Grid item>
                  <Typography className="fontTitleColumnTable">{ORDINE_SCUOLA}</Typography>
                  {dropdown3 ? <OrdineScuola /> : null}
                </Grid>
                <Grid item>
                  <KeyboardArrowDownIcon onClick={() => dispatch(setDropDown3())} sx={{ cursor: "pointer" }} />
                </Grid>
              </Grid>
            </StyledTableCell>
            <StyledTableCell align="center" sx={{ width: "200px" }}>
              <Grid container justifyContent={"center"}>
                <Grid item>
                  <Typography className="fontTitleColumnTable">{TITOLO_PROGETTO}</Typography>
                  {dropdown4 ? <Titolo dropDown={setDropDown4()} /> : null}
                </Grid>
                <Grid item>
                  <KeyboardArrowDownIcon onClick={() => dispatch(setDropDown4())} sx={{ cursor: "pointer" }} />
                </Grid>
              </Grid>
            </StyledTableCell>
            <StyledTableCell align="center" sx={{ width: "120px" }}>
              <Grid container justifyContent={"center"}>
                <Grid item>
                  <Typography className="fontTitleColumnTable">{REFERENTE}</Typography>
                  {dropdown5 ? <Referente dropDown={setDropDown5()} /> : null}
                </Grid>
                <Grid item>
                  <KeyboardArrowDownIcon onClick={() => dispatch(setDropDown5())} sx={{ cursor: "pointer" }} />
                </Grid>
              </Grid>
            </StyledTableCell>
            <StyledTableCell align="center" sx={{ width: "190px" }}>
              <Grid container justifyContent={"center"}>
                <Grid item>
                  <Typography className="fontTitleColumnTable">{DATA_INSERIMENTO}</Typography>
                  {dropdown6 ? <DataInserimento dropDown={setDropDown6()} /> : null}
                </Grid>
                <Grid item>
                  <KeyboardArrowDownIcon onClick={() => dispatch(setDropDown6())} sx={{ cursor: "pointer" }} />
                </Grid>
              </Grid>
            </StyledTableCell>
            <StyledTableCell align="center" sx={{ width: "50px" }}>
              <Typography className="fontTitleColumnTable">{DETTAGLI}</Typography>
            </StyledTableCell>
            <StyledTableCell align="center" sx={{ width: "190px" }}>
              <Typography className="fontTitleColumnTable">{AZIONI}</Typography>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.slice(valore1slice, valore2slice).map((row, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell align="center">{row.completamento}</StyledTableCell>
              <StyledTableCell align="center">{row.denominazioneScuola}</StyledTableCell>
              <StyledTableCell align="center">{row.provincia}</StyledTableCell>
              <StyledTableCell align="center">{row.ordineScuola}</StyledTableCell>
              <StyledTableCell align="center">{row.titolo}</StyledTableCell>
              <StyledTableCell align="center">{row.referente}</StyledTableCell>
              <StyledTableCell align="center">{row.dataInserimento}</StyledTableCell>
              <StyledTableCell align="center">{row.dettagliProgetto}</StyledTableCell>
              <StyledTableCell align="center">{row.azioni}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
