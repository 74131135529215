import { useNavigate } from "react-router-dom";
import { Box, Divider, Grid, IconButton, Typography, Link, Stack } from "@mui/material";
import { BackIcon, HomeIcon } from "../../styles/icons";
import { PATH_DETTAGLI_PROGETTO, PATH_HOME, PATH_SCHEDA_ISTITUTO } from "../../path";
import { labels } from "../../labels";
import { useDispatch, useSelector } from "react-redux";
import { getCodiceMecc, getIdProgetto, resetAttivitaId, resetFiltriProgetti, resetIndex } from "../../modules/configuration";
import { fetchDettagliProgetto, fetchDettagliProgettoReset, fetchSezioneProgettiIstituto } from "../../modules/datiProgetti";
import { fetchSezioneIstituto } from "../../modules/istituti";

const { BUTTON_HOME } = labels;

export const HeaderPage = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let id = useSelector(getIdProgetto);
  let codMeccanografico = useSelector(getCodiceMecc);
  return (
    <Box className="header">
      <Grid container spacing={2}>
        <Grid item xs={9} sm={10} md={11} className="leftElement">
          {props.linkBack === true && (
            <IconButton
              aria-label="Back"
              className="backButton"
              onClick={() => {
                if (props.path === PATH_SCHEDA_ISTITUTO) {
                  dispatch(fetchSezioneIstituto(codMeccanografico));
                  dispatch(fetchSezioneProgettiIstituto(codMeccanografico));
                }
                if (props.path === PATH_DETTAGLI_PROGETTO) {
                  dispatch(fetchDettagliProgetto("/" + id));
                }
                dispatch(resetIndex());
                if (props.resetFiltri !== false) {
                  dispatch(resetFiltriProgetti());
                }
                dispatch(fetchDettagliProgettoReset());
                navigate(props.path);
                dispatch(resetAttivitaId());
              }}
            >
              <BackIcon />
            </IconButton>
          )}
          <Stack className="customIcon" sx={{ display: { xs: props.linkBack === true ? "none" : "flex", md: "flex" } }}>
            {props.icon}
          </Stack>
          <Typography className="titlePage" variant="h1">
            {props.title} {props.tipoPerTitle} {props.titleProg}
          </Typography>
        </Grid>
        <Grid item xs={3} sm={2} md={1} className="buttonHome">
          <IconButton
            aria-label="Home"
            onClick={() => {
              navigate(PATH_HOME);
              dispatch(resetIndex());
              dispatch(resetFiltriProgetti());
              dispatch(fetchDettagliProgettoReset());
            }}
          >
            <HomeIcon />
          </IconButton>
          <Link
            onClick={() => {
              navigate(PATH_HOME);
              dispatch(resetIndex());
              dispatch(resetFiltriProgetti());
              dispatch(fetchDettagliProgettoReset());
            }}
            className="link"
            sx={{ cursor: "pointer" }}
          >
            {BUTTON_HOME}
          </Link>
        </Grid>
      </Grid>
      <Divider sx={{ borderColor: props.color }} />
    </Box>
  );
};
