import { Grid, Typography, Link } from "@mui/material";
import { getVal1Slice, getVal2Slice, savePath, setCodiceMecc, setIdProgetto } from "../../modules/configuration";
import { useDispatch, useSelector } from "react-redux";
import { DettagliLink } from "../pages/dettagliProgetto/DettagliLink";
import { labels } from "../../labels";
import { format } from "../pages/gestisciProgetti/TableProgetti";
import PrintIcon from "@mui/icons-material/Print";
import { MobileScreenShareOutlined } from "@mui/icons-material";
import { fetchSezioneIstituto } from "../../modules/istituti";
import { fetchSezioneProgettiIstituto } from "../../modules/datiProgetti";
import { PATH_SCHEDA_ISTITUTO, PATH_SCHEDA_PROFILO } from "../../path";
import { useNavigate } from "react-router-dom";

const {
  CAMPO0_TABLE_RESPONSIVE,
  CAMPO2_TABLE_RESPONSIVE,
  CAMPO3_TABLE_RESPONSIVE,
  CAMPO4_TABLE_RESPONSIVE,
  PROVINCIA,
  ORDINE_SCUOLA,
  DETTAGLI_TABLE_RESPONSIVE,
  BUTTON_SCARICA,
  BUTTON_CONDIVIDI,
} = labels;

export const TableResponsive = (props) => {
  let progetti = props.progetti;
  const valore1slice = useSelector(getVal1Slice);
  const valore2slice = useSelector(getVal2Slice);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function SortArray(x, y) {
    let varx = x.data_inserimento;
    let vary = y.data_inserimento;
    if (x.data_inserimento === null) {
      varx = "1900-01-01 00:00:00";
    }
    return new Date(varx) - new Date(vary);
  }

  progetti = [...progetti].sort(SortArray);
  progetti = progetti.reverse();

  return (
    <Grid container className="tableResponsive">
      {progetti.slice(valore1slice, valore2slice).map((elem, index) => (
        <Grid item xs={12} sm={12} md={12} xl={12} sx={{ marginTop: "30px", marginBottom: "30px" }} key={index}>
          <Grid container sx={{ display: "block" }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              xl={12}
              sx={{
                borderColor: props.borderColor,
                bgcolor: props.color,
              }}
              className="center borderTableResponsive"
            >
              <Typography className="titleTableResponsive">{elem.titolo_progetto}</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} xl={12}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  xl={6}
                  sx={{
                    borderColor: props.borderColor,
                  }}
                  className="borderTableResponsive"
                >
                  <Typography className="campiTableResponsive">{CAMPO0_TABLE_RESPONSIVE}</Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  xl={6}
                  sx={{
                    borderColor: props.borderColor,
                  }}
                  className="borderTableResponsive"
                >
                  {" "}
                  <Typography
                    sx={{
                      marginTop: "10px",
                      marginLeft: "10px",
                    }}
                  >
                    {" "}
                    {elem.completamento === null ? "non inserito" : elem.completamento + "%"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  xl={6}
                  sx={{
                    borderColor: props.borderColor,
                  }}
                  className="borderTableResponsive"
                >
                  <Typography className="campiTableResponsive">{CAMPO2_TABLE_RESPONSIVE}</Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  xl={6}
                  sx={{
                    borderColor: props.borderColor,
                  }}
                  className="borderTableResponsive"
                >
                  <Link
                    onClick={() => {
                      dispatch(setCodiceMecc(elem.codice_scuola));
                      dispatch(fetchSezioneIstituto(elem.codice_scuola));
                      dispatch(fetchSezioneProgettiIstituto(elem.codice_scuola));
                      navigate(PATH_SCHEDA_ISTITUTO);
                      dispatch(savePath(props.path));
                    }}
                    sx={{ color: "black !important", textDecorationColor: "black !important", cursor: "pointer" }}
                  >
                    <Typography
                      sx={{
                        marginTop: "10px",
                        marginLeft: "10px",
                      }}
                    >
                      {" "}
                      {elem.nomeScuola === null ? " " : elem.nomeScuola}
                    </Typography>
                  </Link>{" "}
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  xl={6}
                  sx={{
                    borderColor: props.borderColor,
                  }}
                  className="borderTableResponsive"
                >
                  <Typography className="campiTableResponsive">{PROVINCIA}</Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  xl={6}
                  sx={{
                    borderColor: props.borderColor,
                  }}
                  className="borderTableResponsive"
                >
                  {" "}
                  <Typography
                    sx={{
                      marginTop: "10px",
                      marginLeft: "10px",
                    }}
                  >
                    {" "}
                    {elem.provincia}{" "}
                  </Typography>{" "}
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  xl={6}
                  sx={{
                    borderColor: props.borderColor,
                  }}
                  className="borderTableResponsive"
                >
                  <Typography className="campiTableResponsive">{ORDINE_SCUOLA}</Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  xl={6}
                  sx={{
                    borderColor: props.borderColor,
                  }}
                  className="borderTableResponsive"
                >
                  {" "}
                  <Typography
                    sx={{
                      marginTop: "10px",
                      marginLeft: "10px",
                    }}
                  >
                    {elem.descr_grado_scuola}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  xl={6}
                  sx={{
                    borderColor: props.borderColor,
                  }}
                  className="borderTableResponsive"
                >
                  <Typography className="campiTableResponsive">{CAMPO4_TABLE_RESPONSIVE}</Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  xl={6}
                  sx={{
                    borderColor: props.borderColor,
                  }}
                  className="borderTableResponsive"
                >
                  <Typography
                    sx={{
                      marginTop: "10px",
                      marginLeft: "10px",
                    }}
                  >
                    {/* <Link
                      sx={{ color: "black !important", textDecorationColor: "black !important", cursor: "pointer !important" }}
                      onClick={() => {
                        dispatch(setIdProgetto(elem.uuid_progetto));
                        navigate(PATH_SCHEDA_PROFILO);
                        dispatch(savePath(props.path));
                      }}
                    > */}
                      {(elem.referente_cogn !== null ? elem.referente_cogn : "") + " " + (elem.referente_nome !== null ? elem.referente_nome : "")}{" "}
                    {/* </Link>{" "} */}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  xl={6}
                  sx={{
                    borderColor: props.borderColor,
                  }}
                  className="borderTableResponsive"
                >
                  <Typography className="campiTableResponsive">{CAMPO3_TABLE_RESPONSIVE}</Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  xl={6}
                  sx={{
                    borderColor: props.borderColor,
                  }}
                  className="borderTableResponsive"
                >
                  {" "}
                  <Typography
                    sx={{
                      marginTop: "10px",
                      marginLeft: "10px",
                    }}
                  >
                    {format(new Date(elem.data_inserimento), "dd-MM-yyyy")}{" "}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                borderColor: props.borderColor,
              }}
              className="borderTableResponsive"
            >
              <Grid
                container
                sx={{
                  borderColor: props.borderColor,
                  marginTop: "10px",
                }}
              >
                <Grid item xs={4}>
                  <Grid container sx={{ justifyContent: "center", display: "flex" }}>
                    <Grid item xs={12} sx={{ justifyContent: "center", display: "flex", marginTop: "-20px" }}>
                      <DettagliLink path={props.path} id={elem.uuid_progetto} />
                    </Grid>
                    <Grid item xs={12} sx={{ justifyContent: "center", display: "flex", marginTop: "-20px" }}>
                      <Typography> {DETTAGLI_TABLE_RESPONSIVE}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4} sx={{ justifyContent: "center", display: "flex" }}>
                  <Link
                    onClick={() => window.open(window.origin + "/shared/v1/projects/" + elem.uuid_progetto + "?print=true", "_blank")}
                    sx={{
                      justifyContent: "center",
                      display: "flex",
                      color: "black !important",
                      cursor: "pointer",
                      textDecoration: "none",
                      ":hover": {
                        textDecoration: "underline",
                      },
                    }}
                  >
                    <Grid container sx={{ justifyContent: "center", display: "flex" }}>
                      <Grid item xs={12} sx={{ justifyContent: "center", display: "flex" }}>
                        <PrintIcon sx={{ color: "black" }} />
                      </Grid>

                      <Grid item xs={12} sx={{ justifyContent: "center", display: "flex" }}>
                        <Typography> {BUTTON_SCARICA}</Typography>
                      </Grid>
                    </Grid>
                  </Link>
                </Grid>
                <Grid item xs={4} sx={{ justifyContent: "center", display: "flex" }}>
                  <Link
                    onClick={() => window.open(window.origin + "/shared/v1/projects/" + elem.uuid_progetto + "?print=false", "_blank")}
                    sx={{
                      color: "black !important",
                      cursor: "pointer",
                      textDecoration: "none",
                      ":hover": {
                        textDecoration: "underline",
                      },
                    }}
                  >
                    <Grid container>
                      <Grid item xs={12} sx={{ justifyContent: "center", display: "flex" }}>
                        <MobileScreenShareOutlined sx={{ color: "black" }} />
                      </Grid>
                      <Grid item xs={12} sx={{ justifyContent: "center", display: "flex" }}>
                        <Typography>{BUTTON_CONDIVIDI}</Typography>
                      </Grid>
                    </Grid>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};
