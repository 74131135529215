import { Box, Card, Grid, Typography, Link, Avatar } from "@mui/material";
import { LoadingSpinner } from "../../shared";
import { HeaderPage } from "../../shared/Header";
import { ProfiloIcon } from "../../../styles/icons";
import { labels } from "../../../labels";
import { useDispatch, useSelector } from "react-redux";
import { getPath, savePath } from "../../../modules/configuration";
import { PATH_PROFILO, PATH_SCHEDA_ISTITUTO } from "../../../path";
import { useNavigate } from "react-router-dom";

const { SCHEDA_UTENTE, BIOGRAFIA, PROFILO_ISTITUTO_APPARTENENZA, MAIL_LABEL, SOCIAL_LABEL } = labels;

export const SchedaProfilo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const path = useSelector(getPath);

  return (
    <Box>
      {false ? (
        <Box>
          <LoadingSpinner loading={true} />
        </Box>
      ) : (
        <Box>
          <HeaderPage linkBack={true} icon={<ProfiloIcon />} title={SCHEDA_UTENTE} color={"#105b70"} path={path} />
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card sx={{ padding: "20px" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={5} md={2}>
                      <Avatar className="AvatarSchedaProfilo" src={""} alt="Immagine profilo" sx={{}} />
                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                      <Typography>
                        {" "}
                        <Card sx={{ padding: "20px" }}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Typography sx={{ fontSize: "19px !important", fontWeight: "Bold", cursor: "default" }}>Carlini Chiara</Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container>
                                <Grid item xs={12} sm={2}>
                                  <Typography className="textTitle">{MAIL_LABEL}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={10}>
                                  <Link
                                    sx={{ cursor: "pointer", fontSize: "18px", wordWrap: "break-word", color: "black", textDecorationColor: "black" }}
                                    // onClick={() => window.open(datiIstituto.data.length !== 0 ? "mailto:" + datiIstituto.data[0].email : null)}
                                  >
                                    <Typography className="dato">{"prova@gmail.com"}</Typography>
                                  </Link>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container>
                                <Grid item xs={12} sm={3}>
                                  <Typography className="textTitle">{SOCIAL_LABEL}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={9}>
                                  <Link
                                    sx={{ cursor: "pointer", fontSize: "18px", wordWrap: "break-word", color: "black", textDecorationColor: "black" }}
                                    // onClick={() => window.open(datiIstituto.data.length !== 0 ? datiIstituto.data[0].sito_web : null)}
                                  >
                                    <Typography className="dato">{"www.provail.com"}</Typography>
                                  </Link>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Card>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={5}>
                      <Card sx={{ padding: "20px" }}>
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography sx={{ fontSize: "25px !important", fontWeight: "Bold", cursor: "default" }}>{BIOGRAFIA}</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography className="dato">
                              {
                                "GECF00300A - FONDAZIONE E.FASSICOMO - SCUOLA GRAFICA GENOVESE GECF00300A - FONDAZIONE E.FASSICOMO - SCUOLA GRAFICA GENOVESEGECF00300A - FONDAZIONE E.FASSICOMO - SCUOLA GRAFICA GENOVESEGECF00300A - FONDAZIONE E.FASSICOMO - SCUOLA GRAFICA GENOVESEGECF00300A - FONDAZIONE E.FASSICOMO - SCUOLA GRAFICA GENOVESE "
                              }
                            </Typography>
                          </Grid>
                        </Grid>
                      </Card>{" "}
                    </Grid>
                    <Grid item xs={12}>
                      <Card sx={{ padding: "20px" }}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <Grid item xs={12}>
                              <Typography sx={{ fontSize: "25px !important", fontWeight: "Bold", cursor: "default" }}>
                                {PROFILO_ISTITUTO_APPARTENENZA}
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Link
                                onClick={() => {
                                  // dispatch(setCodiceMecc(profilo.codice));
                                  // dispatch(fetchSezioneIstituto(profilo.codice));
                                  // dispatch(fetchSezioneProgettiIstituto(profilo.codice));
                                  navigate(PATH_SCHEDA_ISTITUTO);
                                  dispatch(savePath(PATH_PROFILO));
                                }}
                                sx={{ color: "black !important", textDecorationColor: "black !important", cursor: "pointer" }}
                              >
                                <Typography>{"GECF00300A - FONDAZIONE E.FASSICOMO - SCUOLA GRAFICA GENOVESE"}</Typography>
                              </Link>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Grid container>
                              <Grid item xs={12} sm={8}>
                                <Typography className="dato" sx={{ marginTop: "35px" }}>
                                  {"provaRuolo"}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Card>{" "}
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
    </Box>
  );
};
