import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography, Link } from "@mui/material";
import { School as SchoolIcon } from "@mui/icons-material";
import {
  PROFILO_CODICE_SCUOLA,
  PROFILO_NOME_SCUOLA,
  PROFILO_RUOLO,
  PROFILO_CODICE_INCARICO,
  PROFILO_INCARICO,
  PROFILO_INCARICO_IN_APPROVAZIONE,
  RUOLO_GES,
} from "../../../modules/profilo";
import { Autocomplete, Select } from "../../";
import { getIncarichi, getIstitutiScolastici } from "../../../modules/common";
import { labels } from "../../../labels";
import { savePath, setCodiceMecc } from "../../../modules/configuration";
import { fetchSezioneIstituto } from "../../../modules/istituti";
import { fetchSezioneProgettiIstituto } from "../../../modules/datiProgetti";
import { PATH_PROFILO, PATH_SCHEDA_ISTITUTO } from "../../../path";
import { useNavigate } from "react-router-dom";

const {
  REQUIRED_FIELD,
  PROFILO_ISTITUTO_APPARTENENZA,
  INCARICO_LABEL,
  PROFILO_INCARICO_PROVVISORIO,
  INCARICO_PLACEHOLDER,
  ISTITUTO_LABEL,
  ISTITUTO_PLACEHOLDER,
  PROFILO_ISTITUOTO_PROVVISORIO,
} = labels;

export const DatiIstituto = ({ datiProfilo, onChange }) => {
  const [istituto, setIstituto] = useState(null);
  const incarico = datiProfilo[PROFILO_CODICE_INCARICO];
  const incarichi = useSelector(getIncarichi);
  const istitutiScolastici = useSelector(getIstitutiScolastici);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (datiProfilo) {
      if (istitutiScolastici) {
        if (datiProfilo[PROFILO_CODICE_SCUOLA] != null && datiProfilo[PROFILO_NOME_SCUOLA] != null)
          // prettier-ignore
          setIstituto(istitutiScolastici.find((istituto) => istituto.codice === datiProfilo[PROFILO_CODICE_SCUOLA] && istituto.descrizione === datiProfilo[PROFILO_NOME_SCUOLA]));
        else setIstituto(null);
      }
    }
    // eslint-disable-next-line
  }, [datiProfilo, istitutiScolastici]);

  return (
    <Grid item xs={12} className="datiIstituto">
      <Grid container spacing={2}>
        <Grid item xs={12} className="headerSezione">
          <SchoolIcon />
          <Typography>{PROFILO_ISTITUTO_APPARTENENZA}</Typography>
        </Grid>
        {istituto && (
          <>
            <Grid item xs={12}>
              <Link
                onClick={() => {
                  dispatch(setCodiceMecc(istituto.codice));
                  dispatch(fetchSezioneIstituto(istituto.codice));
                  dispatch(fetchSezioneProgettiIstituto(istituto.codice));
                  navigate(PATH_SCHEDA_ISTITUTO);
                  dispatch(savePath(PATH_PROFILO));
                }}
                sx={{ color: "black !important", textDecorationColor: "black !important", cursor: "pointer" }}
              >
                <Typography>{istituto.codice + " - " + istituto.descrizione}</Typography>
              </Link>
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <Select
            required
            label={INCARICO_LABEL}
            placeholder={INCARICO_PLACEHOLDER}
            disabled={datiProfilo[PROFILO_INCARICO_IN_APPROVAZIONE]}
            options={incarichi?.filter((incarico) =>
              incarico.visibilita.includes(datiProfilo && datiProfilo[PROFILO_RUOLO] != null ? datiProfilo[PROFILO_RUOLO] : RUOLO_GES)
            )}
            value={incarico}
            onChange={(e, v) => {
              const { value: id, children: descrizione } = v.props;
              onChange([
                { fieldName: PROFILO_CODICE_INCARICO, fieldValue: id },
                { fieldName: PROFILO_INCARICO, fieldValue: descrizione },
              ]);
            }}
            {...(datiProfilo[PROFILO_CODICE_INCARICO] === "" && { error: true, errorText: REQUIRED_FIELD })}
          />
        </Grid>
        {datiProfilo[PROFILO_INCARICO_IN_APPROVAZIONE] && (
          <Grid item xs={12} sx={{ paddingTop: "0 !important", marginTop: { xs: "-10px", md: "-15px" } }}>
            <Typography className="incaricoProvvisorio">{PROFILO_INCARICO_PROVVISORIO}</Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Autocomplete
            required
            label={ISTITUTO_LABEL}
            placeholder={ISTITUTO_PLACEHOLDER}
            disabled={datiProfilo[PROFILO_INCARICO_IN_APPROVAZIONE]}
            options={istitutiScolastici}
            value={istituto}
            onChange={(e, v) => {
              if (v) {
                const { id, codice, descrizione } = v;
                onChange([
                  { fieldName: PROFILO_CODICE_SCUOLA, fieldValue: codice != null ? codice : id },
                  { fieldName: PROFILO_NOME_SCUOLA, fieldValue: codice != null ? descrizione : "" },
                ]);
              }
            }}
            filterOptions={(options, { inputValue }) =>
              options.filter((item) => {
                const { descrizione, codice } = item;
                if (codice != null)
                  return descrizione.toUpperCase().includes(inputValue.toUpperCase()) || codice.toUpperCase().includes(inputValue.toUpperCase());
                else return descrizione.toUpperCase().includes(inputValue.toUpperCase());
              })
            }
            {...(datiProfilo[PROFILO_CODICE_SCUOLA] == null && { error: true, errorText: REQUIRED_FIELD })}
          />
        </Grid>
        {datiProfilo[PROFILO_INCARICO_IN_APPROVAZIONE] && (
          <Grid item xs={12} sx={{ paddingTop: "0 !important", marginTop: { xs: "-10px", md: "-15px" } }}>
            <Typography className="incaricoProvvisorio">{PROFILO_ISTITUOTO_PROVVISORIO}</Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
