import { Box } from "@mui/material";
import { AttivitaResponsive } from "./AttivitaResponsive";
import { AttivitaNormal } from "./AttivitaNormal";
import { AttivitaTablet } from "./AttivitaTablet";
import { labels } from "../../../../labels";
import { useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  changetabs,
  checkIndex,
  getButtonType,
  getDatiProgetto,
  getIndexTabSpostamento,
  getSottoAttivitaId,
  resetFiltriProgetti,
  resetIndex,
  setCheckAttivita,
} from "../../../../modules/configuration";
import { useNavigate } from "react-router-dom";
import { PATH_HOME } from "../../../../path";
import { getAttivitaCurricolari } from "../../../../modules/common";
import { POST_ATTIVITA, PUT_SBLOCCO_MODIFICA } from "../../../../apiUrl";
import { notificationCodes, setNotification } from "../../../../modules/notification";
import { createRequest } from "../../../../utility";

const { DIDATTICA, LAVORO, WEB, COMUNICAZIONE, CONTENUTI, SICUREZZA, PROBLEM_SOLVING } = labels;

export const tabsAttivita = [
  {
    id: "0",
    testo: DIDATTICA,
    priorità: true,
    binocolo: false,
  },
  {
    id: "1",
    testo: LAVORO,
    priorità: true,
    binocolo: false,
  },
  {
    id: "2",
    testo: WEB,
    priorità: true,
    binocolo: false,
  },
  {
    id: "3",
    testo: COMUNICAZIONE,
    priorità: true,
    binocolo: false,
  },
  {
    id: "4",
    testo: CONTENUTI,
    priorità: true,
    binocolo: false,
  },
  {
    id: "5",
    testo: SICUREZZA,
    priorità: true,
    binocolo: false,
  },
  {
    id: "6",
    testo: PROBLEM_SOLVING,
    priorità: true,
    binocolo: false,
  },
];
export const Attivita = (props) => {
  const dispatch = useDispatch();
  const index = useSelector(getIndexTabSpostamento);
  const [avvisoAttObb, setAvvisoAttObb] = useState(false);
  const attivita = useSelector(getAttivitaCurricolari);
  let checkAttOb = false;
  const buttonType = useSelector(getButtonType);
  const navigate = useNavigate();
  const datiProg = useSelector(getDatiProgetto);
  const datiProgetto = datiProg.attivita;
  //MAP AATIIVITA E SOTTOATTIVITA RELATIVE INSERITE DALL'UTENTE
  const attivitaMap = useSelector(getSottoAttivitaId);
  let attMap = new Map(JSON.parse(attivitaMap));

  //DIVIDO ATTIVITA PER TAB
  const checkAttivitaDidattica = (attivita) => {
    const checkCAD = attivita.descr_sottotab === "DIDATTICA";
    return checkCAD;
  };

  const attivitaDidattica = attivita.filter(checkAttivitaDidattica);

  const checkLavoro = (attivita) => {
    const checkCL = attivita.descr_sottotab === "LAVORO";
    return checkCL;
  };

  const attivitaLavoro = attivita.filter(checkLavoro);

  const checkWeb = (attivita) => {
    const checkCW = attivita.descr_sottotab === "WEB";
    return checkCW;
  };

  const attivitaWeb = attivita.filter(checkWeb);

  const checkComunicazione = (attivita) => {
    const checkCC = attivita.descr_sottotab === "COMUNICAZIONE";
    return checkCC;
  };

  const attivitaComunicazione = attivita.filter(checkComunicazione);

  const checkContenuti = (attivita) => {
    const checkCont = attivita.descr_sottotab === "CONTENUTI";
    return checkCont;
  };

  const attivitaContenuti = attivita.filter(checkContenuti);

  const checkSicurezza = (attivita) => {
    const checkCS = attivita.descr_sottotab === "SICUREZZA";
    return checkCS;
  };

  const attivitaSicurezza = attivita.filter(checkSicurezza);

  const checkProblemSolving = (attivita) => {
    const checkCPB = attivita.descr_sottotab === "PROBLEM SOLVING";
    return checkCPB;
  };

  const attivitaPB = attivita.filter(checkProblemSolving);

  //VERIFICO CI SIAN ATTIVITA PER TAB OBBLIGATORIE
  let checkAttDidObb = false;
  let checkAttDid = 0;
  for (let [key] of attMap.entries()) {
    //per eliminare segno obbligatorietà
    // eslint-disable-next-line
    attivitaDidattica.map((opt) =>
      opt.finalita + " " + opt.descr_campo === key && opt.obbligatorieta === 1 ? (checkAttDidObb = checkAttDidObb + 1) : null
    );
    //per eliminare segno presenza attivita non inserite
    // eslint-disable-next-line
    attivitaDidattica.map((opt) => (opt.finalita + " " + opt.descr_campo === key ? (checkAttDid = checkAttDid + 1) : null));
    if (checkAttDidObb === 2) {
      tabsAttivita[0].priorità = false;
    } else {
      tabsAttivita[0].priorità = true;
    }
    if (checkAttDid === 6) {
      tabsAttivita[0].binocolo = false;
    } else {
      tabsAttivita[0].binocolo = true;
    }
  }

  let checkLavObb = 0;
  let checkLav = 0;
  for (let [key] of attMap.entries()) {
    // eslint-disable-next-line
    attivitaLavoro.map((opt) => (opt.finalita + " " + opt.descr_campo === key && opt.obbligatorieta === 1 ? (checkLavObb = checkLavObb + 1) : null));
    // eslint-disable-next-line
    attivitaLavoro.map((opt) => (opt.finalita + " " + opt.descr_campo === key ? (checkLav = checkLav + 1) : null));

    if (checkLavObb === 1) {
      tabsAttivita[1].priorità = false;
    } else {
      tabsAttivita[1].priorità = true;
    }

    if (checkLav === 3) {
      tabsAttivita[1].binocolo = false;
    } else {
      tabsAttivita[1].binocolo = true;
    }
  }

  let checkWebObb = 0;
  for (let [key] of attMap.entries()) {
    // eslint-disable-next-line
    attivitaWeb.map((opt) => (opt.finalita + " " + opt.descr_campo === key && opt.obbligatorieta === 1 ? (checkWebObb = checkWebObb + 1) : null));

    if (checkWebObb === 3) {
      tabsAttivita[2].priorità = false;
    } else {
      tabsAttivita[2].priorità = true;
    }
  }

  let checkComObb = 0;
  for (let [key] of attMap.entries()) {
    // eslint-disable-next-line
    attivitaComunicazione.map(
      // eslint-disable-next-line
      (opt) =>
        // eslint-disable-next-line
        opt.finalita + " " + opt.descr_campo === key && opt.obbligatorieta === 1 ? (checkComObb = checkComObb + 1) : null
      // eslint-disable-next-line
    );

    if (checkComObb === 2) {
      tabsAttivita[3].priorità = false;
    } else {
      tabsAttivita[3].priorità = true;
    }
  }

  let checkCont = 0;
  for (let [key] of attMap.entries()) {
    // eslint-disable-next-line
    attivitaContenuti.map((opt) => (opt.finalita + " " + opt.descr_campo === key && opt.obbligatorieta === 1 ? (checkCont = checkCont + 1) : null));

    if (checkCont === 3) {
      tabsAttivita[4].priorità = false;
    } else {
      tabsAttivita[4].priorità = true;
    }
  }

  let checkSic = 0;
  for (let [key] of attMap.entries()) {
    // eslint-disable-next-line
    attivitaSicurezza.map((opt) => (opt.finalita + " " + opt.descr_campo === key && opt.obbligatorieta === 1 ? (checkSic = checkSic + 1) : null));

    if (checkSic === 1) {
      tabsAttivita[5].priorità = false;
    } else {
      tabsAttivita[5].priorità = true;
    }
  }
  let checkPB = 0;
  for (let [key] of attMap.entries()) {
    // eslint-disable-next-line
    attivitaPB.map((opt) => (opt.finalita + " " + opt.descr_campo === key && opt.obbligatorieta === 1 ? (checkPB = checkPB + 1) : null));

    if (checkPB === 1) {
      tabsAttivita[6].priorità = false;
    } else {
      tabsAttivita[6].priorità = true;
    }
  }

  tabsAttivita[0].priorità === false &&
  tabsAttivita[1].priorità === false &&
  tabsAttivita[2].priorità === false &&
  tabsAttivita[3].priorità === false &&
  tabsAttivita[4].priorità === false &&
  tabsAttivita[5].priorità === false &&
  tabsAttivita[6].priorità === false
    ? (checkAttOb = true)
    : (checkAttOb = false);

  const formik = useFormik({
    initialValues: {},

    onSubmit: () => {
      if (checkAttOb === true) {
        dispatch(setCheckAttivita(false));

        createRequest(POST_ATTIVITA.method, POST_ATTIVITA.baseUrl, POST_ATTIVITA.apiUrl, null, datiProgetto)
          .then((response) => {
            if (response.status === 200) {
              if (buttonType === "AVANTI") {
                if (index !== null) {
                  let versione = true;
                  dispatch(changetabs({ index, versione }));
                } else {
                  dispatch(checkIndex("4"));
                }
              } else {
                createRequest(PUT_SBLOCCO_MODIFICA.method, PUT_SBLOCCO_MODIFICA.baseUrl, PUT_SBLOCCO_MODIFICA.apiUrl, null, {
                  uuid_progetto: datiProgetto[0].uuid_progetto,
                })
                  .then((response) => {
                    if (response.status === 200) {
                      navigate(PATH_HOME);
                      dispatch(resetFiltriProgetti());
                      dispatch(resetIndex());
                    }
                  })
                  .catch((e) => {
                    dispatch(
                      setNotification({
                        tipo: notificationCodes.ERROR,
                        message: "Errore durante il cambio stato del progetto in modifica",
                        description: "Errore durante il cambio stato del progetto in modifica",
                      })
                    );
                  });
              }
            }
          })
          .catch((e) => {
            dispatch(
              setNotification({
                tipo: notificationCodes.ERROR,
                message: "Errore durante il salvataggio dei dati della sezione attività ",
                description: "Si è verificato un errore imprevisto nel salvataggio dei dati della sezione attività",
              })
            );
          });
      } else {
        setAvvisoAttObb(true);
        dispatch(setCheckAttivita(true));
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} id={"myForm_"}>
      <Box>
        <Box
          sx={{
            paddingBottom: "50px",
            "@media (max-width:480px)": { paddingBottom: "0px", display: "none" },
          }}
        >
          <AttivitaNormal
            handlesubmit={formik.handleSubmit}
            formik={formik}
            color2={props.color2}
            color1={props.color1}
            progetto={props.progetto}
            Dettagli={props.Dettagli}
            slice={"0,7"}
            modal={"normal"}
            modalSpostamentoTab={props.modal}
            setModalSpostamentoTab={props.setModalSpostamentoTab}
            versione={props.versione}
            checkAttOb={avvisoAttObb}
          />
        </Box>
        <Box
          sx={{
            paddingBottom: "50px",
            display: "none",
            "@media (max-width:1100px)": { display: "block" },
            "@media (max-width:480px)": { display: "none" },
          }}
        >
          <AttivitaTablet
            handlesubmit={formik.handleSubmit}
            formik={formik}
            checkAttOb={avvisoAttObb}
            color2={props.color2}
            color1={props.color1}
            progetto={props.progetto}
            Dettagli={props.Dettagli}
            slice={"4,7"}
            modal={"responsive"}
            modalSpostamentoTab={props.modal}
            setModalSpostamentoTab={props.setModalSpostamentoTab}
            versione={props.versione}
          />{" "}
        </Box>
        <Box
          sx={{
            display: "none",
            "@media (max-width:480px)": { display: "block" },
          }}
        >
          <AttivitaResponsive
            handlesubmit={formik.handleSubmit}
            formik={formik}
            checkAttOb={avvisoAttObb}
            Dettagli={props.Dettagli}
            color2={props.color2}
            color1={props.color1}
            progetto={props.progetto}
            modal={"responsive"}
            modalSpostamentoTab={props.modal}
            setModalSpostamentoTab={props.setModalSpostamentoTab}
            versione={props.versione}
          />
        </Box>
      </Box>
    </form>
  );
};
