import { PATH_ISTITUTI_SCOLASTICI, PATH_SCHEDA_ISTITUTO } from "../../../../path";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import { useDispatch } from "react-redux";
import { savePath, setCodiceMecc } from "../../../../modules/configuration/actions";
import { fetchSezioneIstituto } from "../../../../modules/istituti";
import { fetchSezioneProgettiIstituto } from "../../../../modules/datiProgetti";

export const SchedaIstitutoIconLink = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const codMeccanografico = props.codiceScuola;
  return (
    <Button
      aria-label={"Scheda istituto " + codMeccanografico}
      onClick={() => {
        dispatch(setCodiceMecc(codMeccanografico));
        dispatch(fetchSezioneIstituto(codMeccanografico));
        dispatch(fetchSezioneProgettiIstituto(codMeccanografico));
        navigate(PATH_SCHEDA_ISTITUTO);
        dispatch(savePath(PATH_ISTITUTI_SCOLASTICI));
      }}
    >
      <FileCopyOutlinedIcon sx={{ color: "black" }} />
    </Button>
  );
};
