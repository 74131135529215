import { Grid, Typography,Link } from "@mui/material";
import { SchedaIstitutoIconLink } from "../pages/ricercaOsservatorio/istituti/SchedaIstitutoIconLink";
import { labels } from "../../labels";
import { getVal1Slice, getVal2Slice, savePath, setCodiceMecc } from "../../modules/configuration";
import { useDispatch, useSelector } from "react-redux";
import { fetchSezioneIstituto } from "../../modules/istituti";
import { fetchSezioneProgettiIstituto } from "../../modules/datiProgetti";
import { PATH_ISTITUTI_SCOLASTICI, PATH_SCHEDA_ISTITUTO } from "../../path";
import { useNavigate } from "react-router-dom";

const { CODICE_MECCANOGRAFICO, TIPOLOGIA_ISTITUTO, COMUNE } = labels;

export const TableResponsiveIstituti = (props) => {
  let data = props.progetti;
  const valore1slice = useSelector(getVal1Slice);
  const valore2slice = useSelector(getVal2Slice);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Grid
      container
      sx={{
        display: "none",
        "@media (max-width:900px)": {
          display: "block",
        },
      }}
    >
      {data.slice(valore1slice, valore2slice).map((elem, index) => (
        <Grid item xs={12} sm={12} md={12} xl={12} sx={{ marginTop: "30px", marginBottom: "30px" }} key={index}>
          <Grid container sx={{ display: "block" }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              xl={12}
              className="center"
              sx={{
                border: "solid",
                borderWidth: "1px",
                borderColor: props.borderColor,
                bgcolor: props.color,
              }}
            >
              {" "}
              <Link
                onClick={() => {
                  dispatch(setCodiceMecc(elem.codice_scuola));
                  dispatch(fetchSezioneIstituto(elem.codice_scuola));
                  dispatch(fetchSezioneProgettiIstituto(elem.codice_scuola));
                  navigate(PATH_SCHEDA_ISTITUTO);
                  dispatch(savePath(PATH_ISTITUTI_SCOLASTICI));
                }}
                sx={{ color: "black !important", textDecorationColor: "black !important", cursor: "pointer" }}
              >
                <Typography
                  sx={{
                    fontWeight: "bolder",
                    marginTop: "10px",
                    marginLeft: "10px",
                  }}
                >
                  {" "}
                  {elem.nome}
                </Typography>{" "}
              </Link>
              ,
            </Grid>
            <Grid item xs={12} sm={12} md={12} xl={12}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  xl={6}
                  sx={{
                    border: "solid",
                    borderWidth: "1px",
                    borderColor: props.borderColor,
                  }}
                >
                  <Typography
                    sx={{
                      marginTop: "10px",
                      marginLeft: "10px",
                      fontWeight: "bolder",
                    }}
                  >
                    {CODICE_MECCANOGRAFICO}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  xl={6}
                  sx={{
                    border: "solid",
                    borderColor: props.borderColor,
                    borderWidth: "1px",
                  }}
                >
                  {" "}
                  <Typography
                    sx={{
                      marginTop: "10px",
                      marginLeft: "10px",
                    }}
                  >
                    {elem.codice_scuola}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  xl={6}
                  sx={{
                    border: "solid",
                    borderWidth: "1px",
                    borderColor: props.borderColor,
                  }}
                >
                  <Typography
                    sx={{
                      marginTop: "10px",
                      marginLeft: "10px",
                      fontWeight: "bolder",
                    }}
                  >
                    {TIPOLOGIA_ISTITUTO}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  xl={6}
                  sx={{
                    border: "solid",
                    borderColor: props.borderColor,
                    borderWidth: "1px",
                  }}
                >
                  {" "}
                  <Typography
                    sx={{
                      marginTop: "10px",
                      marginLeft: "10px",
                    }}
                  >
                    {" "}
                    {elem.descr_istituto}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  xl={6}
                  sx={{
                    border: "solid",
                    borderWidth: "1px",
                    borderColor: props.borderColor,
                  }}
                >
                  <Typography
                    sx={{
                      marginTop: "10px",
                      marginLeft: "10px",
                      fontWeight: "bolder",
                    }}
                  >
                    {COMUNE}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  xl={6}
                  sx={{
                    border: "solid",
                    borderColor: props.borderColor,
                    borderWidth: "1px",
                  }}
                >
                  {" "}
                  <Typography
                    sx={{
                      marginTop: "10px",
                      marginLeft: "10px",
                    }}
                  >
                    {elem.descr_com}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              xl={12}
              sx={{
                border: "solid",
                borderWidth: "1px",
                borderColor: props.borderColor,
                height: "70px",
              }}
            >
              <Grid container>
                <Grid item xs={12} sm={12} md={12} xl={12} sx={{ justifyContent: "center", display: "flex" }}>
                  {" "}
                  <SchedaIstitutoIconLink codiceScuola={elem.codice_scuola} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} xl={12} sx={{ justifyContent: "center", display: "flex" }}>
                  <Typography sx={{ fontWeight: "Bold" }}>Scheda istituto</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};
